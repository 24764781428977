
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions, Modules } from "@/store/enums/AdminStoreEnums";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import SubmitButton from "@/components/SubmitButton.vue";

export default defineComponent({
  name: "AdminModeratorEditModal",
  components: { SubmitButton },
  props: {
    moderator: Object,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    let isLoading = computed(() => {
      return store.getters[Modules.MODERATOR + "getIsLoading"];
    });

    let moderator = ref(props.moderator);

    const modalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLButtonElement | null>(null);

    const schema = yup.object({
      name: yup.string().required().max(64).label("Name"),
      password: yup.string().nullable().max(15).label("Password"),
      status: yup.string().required().label("Status"),
    });

    const { meta, errors, handleSubmit, isSubmitting, setErrors } = useForm({
      initialValues: {
        name: "",
        password: "",
        status: "",
      },
      validationSchema: schema,
    });

    const { value: name } = useField("name");
    const { value: password } = useField("password");
    const { value: status } = useField("status");

    const onSubmit = handleSubmit((values) => {
      return new Promise<void>((resolve) => {
        let payload = {
          id: moderator.value?.id,
          values: values,
        };
        store
          .dispatch(Modules.MODERATOR + Actions.UPDATE_MODERATOR, payload)
          .then(() => {
            Swal.fire({
              text: store.getters[Modules.MODERATOR + "getSuccessMessage"],
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              hideModal(modalRef.value);
              moderator.value = {};
              name.value = "";
              password.value = "";
              status.value = "";
              store.dispatch(Modules.MODERATOR + Actions.FETCH_MODERATORS, {
                search: "",
                url: "",
              });
            });
          })
          .catch(() => {
            setErrors(store.getters[Modules.MODERATOR + "getErrors"]);
            Swal.fire({
              text: "Failed to update the moderator. Please fix the errors.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          })
          .finally(() => {
            resolve();
          });
      });
    });

    watch(
      () => props.moderator,
      (newValue) => {
        moderator.value = newValue;
        if (moderator.value) {
          name.value = moderator.value.name;
          password.value = "";
          status.value = moderator.value.status;
        }
      }
    );

    return {
      isLoading,
      name,
      password,
      status,

      modalRef,
      submitButton,

      meta,
      errors,
      onSubmit,
      isSubmitting,
    };
  },
});
