
import { defineComponent, onMounted, computed, ref } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions, Modules } from "@/store/enums/AdminStoreEnums";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import SubmitButton from "@/components/SubmitButton.vue";

export default defineComponent({
  name: "AdminModeratorCreateModal",
  components: { SubmitButton },
  setup() {
    const store = useStore();
    const router = useRouter();

    let isLoading = computed(() => {
      return store.getters[Modules.MODERATOR + "getIsLoading"];
    });

    const modalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLButtonElement | null>(null);

    const schema = yup.object({
      name: yup.string().required().max(64).label("Name"),
      email: yup.string().required().email().max(64).label("Email"),
      password: yup.string().required().min(6).max(15).label("Password"),
    });

    const { meta, errors, handleSubmit, isSubmitting, setErrors } = useForm({
      initialValues: {
        name: "",
        email: "",
        password: "",
      },
      validationSchema: schema,
    });

    const { value: name } = useField("name");
    const { value: email } = useField("email");
    const { value: password } = useField("password");

    const onSubmit = handleSubmit((values) => {
      return new Promise<void>((resolve) => {
        store
          .dispatch(Modules.MODERATOR + Actions.STORE_MODERATOR, values)
          .then(() => {
            Swal.fire({
              text: store.getters[Modules.MODERATOR + "getSuccessMessage"],
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              hideModal(modalRef.value);
              store.dispatch(Modules.MODERATOR + Actions.FETCH_MODERATORS, {
                search: "",
                url: "",
              });
            });
          })
          .catch(() => {
            setErrors(store.getters[Modules.MODERATOR + "getErrors"]);
            Swal.fire({
              text: "Failed to create the moderator. Please fix the errors.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          })
          .finally(() => {
            resolve();
          });
      });
    });

    return {
      isLoading,
      name,
      email,
      password,

      modalRef,
      submitButton,

      meta,
      errors,
      onSubmit,
      isSubmitting,
    };
  },
});
