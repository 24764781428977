
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Actions, Modules } from "@/store/enums/AdminStoreEnums";
import { Modules as MainModules } from "@/store/enums/StoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { formatDateTime } from "@/core/helpers/date";
import Pagination from "@/components/Pagination.vue";
import ModeratorCreateModal from "@/views/admin/moderators/CreateModal.vue";
import ModeratorEditModal from "@/views/admin/moderators/EditModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "AdminModerators",
  components: { Pagination, ModeratorCreateModal, ModeratorEditModal },
  setup() {
    const store = useStore();
    const route = useRoute();

    let search = ref("");
    let currentPageURL = ref("");

    let isLoading = computed(() => {
      return store.getters[Modules.MODERATOR + "getIsLoading"];
    });
    let moderators = computed(() => {
      return store.getters[Modules.MODERATOR + "getModerators"];
    });
    let moderatorsMeta = computed(() => {
      return store.getters[Modules.MODERATOR + "getMeta"];
    });
    let moderator = ref({});

    onMounted(() => {
      setCurrentPageTitle("Moderators");
    });

    fetchRecords();

    function refresh() {
      fetchRecords();
    }

    function searchNow() {
      currentPageURL.value = "";
      fetchRecords();
    }

    function gotoPage(url) {
      currentPageURL.value = url;
      fetchRecords();
    }

    function fetchRecords() {
      store.dispatch(Modules.MODERATOR + Actions.FETCH_MODERATORS, {
        search: search.value,
        url: currentPageURL.value,
      });
    }

    function deleteRecord(id) {
      if (confirm("Are you sure do you want to delete it?")) {
        store
          .dispatch(Modules.MODERATOR + Actions.DELETE_MODERATOR, id)
          .then(() => {
            Swal.fire({
              text: store.getters[Modules.MODERATOR + "getSuccessMessage"],
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              fetchRecords();
            });
          })
          .catch(() => {
            Swal.fire({
              text: "Failed to delete the moderator. Please fix the errors.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
    }

    return {
      formatDateTime,
      search,
      isLoading,
      moderators,
      moderatorsMeta,
      moderator,
      refresh,
      searchNow,
      gotoPage,
      deleteRecord,
    };
  },
});
